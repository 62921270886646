import React from "react"
import { graphql } from "gatsby"

import TeachingPage from "../components/teachingView"
import SEO from "../components/seo"

const Vocal = ({ data }) => {

  const hero = data.mdx.frontmatter.hero.childImageSharp.fluid.src
  const heading = data.mdx.frontmatter.heading
  const buttonText = data.mdx.frontmatter.buttonText
  const buttonLink = data.mdx.frontmatter.buttonLink
  const extraImage = data.mdx.frontmatter.image ? data.mdx.frontmatter.image.childImageSharp.fluid : null
  const imageLink = data.mdx.frontmatter.imageLink

  return (
    <div id="top">
      <SEO
        title="Vocal Technique"
        keywords={['vocal teaching', 'vocal coaching', 'singing lessons', 'vocal technique']}
      />


<TeachingPage hero={hero} title={heading} buttonLink={buttonLink} buttonText={buttonText} extraImage={extraImage} imageLink={imageLink}>
        {data.mdx.body}
      </TeachingPage>
    </div >
  )
}



export default Vocal

export const query = graphql`
query Vocal {
    mdx(fileAbsolutePath: {regex: "/vocal-technique.md/"}) {
      frontmatter {
        heading
        hero {
          childImageSharp {
            fluid(maxWidth: 2500) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        buttonText
        buttonLink
        image {
          childImageSharp {
            fluid(maxWidth: 2500) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageLink
      }
      body
    }
  }
`